class DenatranRulesResource extends RESTFullResource {
  static endpoint() {
    return 'denatran_rules';
  }

  static index(params = {}) {
    return this.request('GET', '/', params);
  }

  static show(id) {
    return this.request('GET', id);
  }

  static store(form = {}) {
    return this.request('POST', '/', form);
  }

  static update(id, form = {}) {
    return this.request('PUT', id, form);
  }

  static delete(id, form = {}) {
    return this.request('DELETE', id);
  }
}
