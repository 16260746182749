class StateResource extends RESTFullResource {
    static endpoint () {
        return 'states';
    }
    static index () {
        return this.request('GET', '/');
    }
    static show (id) {
        return this.request('GET', id);
    }
    static cities (id) {
        return this.request('GET', `${id}/cities`);
    }
    static city (id) {
        return this.request('GET', `/city/${id}`);
    }
}