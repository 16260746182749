class NotificationsResource extends RESTFullResource {
    static endpoint() {
        return 'notifications';
    }
    static index(page = 1, q) {
        const params = {
            page,
            q
        };
        return this.request('GET', '/', params);
    }
    static show(id) {
        return this.request('GET', id);
    }
    static store(data) {
        return this.request('POST', '/', data);
    }
}