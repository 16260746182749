class App {
    static start () {
        document.title = Env.get('PROJECT_NAME', 'sweetheart');   
        const router = Router.createVueRouter();
        const app = new Vue({
        router,
        mounted() {
            document.addEventListener('required-password', this.handleCustomEvent);
        },
        beforeDestroy() {
            document.removeEventListener('required-password', this.handleCustomEvent);
        },
        methods: {
            handleCustomEvent(event) {
            // Handle the custom event here
   
            this.$router.replace(Env.get('CHANGE_PASSWORD_REQUIRED'));
            // Perform any necessary actions based on the event data
            }
        }
        }).$mount('#app');
        return app;
    }
}

