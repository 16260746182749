class InstallationsResource extends RESTFullResource {
    static endpoint () {
        return 'installations';
    }
    static index (page = 1, q) {
        const params = {
            page,
            ...q
        };
        return this.request('GET', '/', params);
    }
    static show (id) {
        return this.request('GET', id);
    }
    static store (data) {
        return this.request('POST', '/', data);
    }
    static update (id, data) {
        return this.request('PUT', id, data);
    }
    static destroy (id) {
        return this.request('DELETE', id);
    }
    static updateChecklist (id, data) {
        return this.request('PUT', `${id}/checklist`, data);
    }
    static getUrlReport (id) {
        return `${Env.get('API_URL')}/${this.endpoint()}/${id}/print`;
    }
    static print (id) {
        return this.request('GET', `${id}/print`);
    }
}