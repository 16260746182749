class InspectionResource extends RESTFullResource {
    static endpoint () {
        return 'inspections';
    }
    static questions () {
        return this.request('GET', 'questions');
    }
    static answers () {
        return this.request('GET', 'answers');
    }
    static photosCategories () {
        return this.request('GET', 'photos');
    }
    static show (id) {
        return this.request('GET', id);
    }
    static reply (id, data) {
        return this.request('POST', `${id}/reply`, data);
    }
    static replyPhoto (id, data) {
        return this.request('POST', `${id}/photos`, data);
    }
    static deletePhoto (id, category_id) {
        return this.request('DELETE', `${id}/photos/${category_id}`);
    }
    static replyAdditionalPhoto (id, data) {
        return this.request('POST', `${id}/additional_photos`, data);
    }
    static deleteAdditionalPhoto (id, additional_id) {
        return this.request('DELETE', `${id}/additional_photos/${additional_id}`);
    }
    static finalize (id, data) {
        return this.request('POST', `${id}/finalize`, data);
    }
    static getUrlReport (id) {
        return `${Env.get('API_URL')}/${this.endpoint()}/${id}/print`;
    }
    static print (id) {
        return this.request('GET', `${id}/print`);
    }
    static denatranUpdate(id){
        return this.request('POST', `${id}/denatran`);
    }
}