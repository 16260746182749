class UsersResource extends RESTFullResource {
    static endpoint() {
        return 'users';
    }
    static index(page = 1, q) {
        const params = {
            page,
            ...q
        };
        return this.request('GET', '/', params);
    }
    static show(id) {
        return this.request('GET', id);
    }
    static store(data) {
        return this.request('POST', '/', data);
    }
    static update(id, data) {
        return this.request('PUT', id, data);
    }
    static destroy(id) {
        return this.request('DELETE', id);
    }

    static getUsersDeposits(company) {
        return this.request('GET', 'deposits');
    }

    static getUsersByRole(roles) {
        return this.request('GET', '/', {
            role: (Array.isArray(roles) ? roles : [roles]).join(','),
            pagination: 'n'
        });
    }

    static installers() {
        throw new Error('fix me');
    }
}