class DepositsResource extends RESTFullResource {
  static endpoint() {
    return "deposits";
  }
  static index(state = null, city = null, company_id = null) {
    const q = {};
    
    if (state) q.state = state;
    if (city) q.city = city;
    if (company_id) q.company_id = company_id;
    
    return this.request("GET", "/", q);
  }
}

