// -- DECLARE VENDORS --
// @vendor: 'vue-the-mask/dist/vue-the-mask.js'
// @vendor: 'moment/min/moment.min.js'
// @vendor: 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.umd.min.js'
// @vendor: 'v-tooltip/dist/v-tooltip.min.js'
// @vendor: 'vue-multiselect/dist/vue-multiselect.min.js'

// -- INITIALIZE VENDORS --
Vue.use(VueTheMask);
Vue.use(moment);
Vue.use(VTooltip);

Vue.component('vue-ctk-date-time-picker', window['vue-ctk-date-time-picker']);
Vue.component('vue-multiselect', window.VueMultiselect.default);

Vue.filter('datetime', function (value) {
  if (!value) return '';
  return moment(value).format('DD/MM/YYYY HH:mm');
});

Vue.filter('status', function (value) {
  if (!value) return '';
  switch (value) {
    case 'OPEN':
      return 'Aberto';
    case 'PENDING':
      return 'Pendente';
    case 'SCHEDULED':
      return 'Agendado';
    case 'OVERDUE':
      return 'Atrasado';
    case 'FINISHED':
      return 'Finalizado';
    case 'INCOMPLETO':
      return 'Incompleto';
    case 'REPROVADO':
      return 'Reprovado';
    case 'APROVADO':
      return 'Aprovado';
    case 'APROVADO COM APONTAMENTO':
      return 'Aprovado com apontamento';
    case 'PENDING_APPROBATION':
      return 'Aprovação Pendente';
    default:
      return value;
  }
});

Vue.filter('service_type', function (value) {
  if (!value) return '';
  switch (value) {
    case 'INSTALLATION':
      return 'Instalação';
    case 'UNINSTALL':
      return 'Remoção';
    case 'MAINTENANCE':
      return 'Manutenção';
    case 'INSPECTION':
      return 'Vistoria Cautelar';
    default:
      return value;
  }
});

Vue.filter('user_type', function (value) {
  if (!value) return '';
  switch (value) {
    case 'ADMIN':
      return 'Administrador';
    case 'INSTALLER':
      return 'Instalador';
    case 'DEALERSHIP':
      return 'Concessionária';
    case 'INSPECTOR':
      return 'Vistoriador';
    case 'INSPECTOR_MANAGER':
      return 'Supervisor de Vistorias';
    case 'COMPANY_COORDINATOR':
      return 'Coordenador de Empresa';
    case 'TRACKER':
      return 'Operações: Rastreamento';
    default:
      return value;
  }
});

// -- SET ENVIROMENTS VARIABLES --
//
// ENV.set(KEY, VALUE, ENV = 'GLOBAL')
//
// Examples:
//   Env.set('MY_KEY', 'c4ca4238a0b923820dcc509a6f75849b');
//   Env.set('MY_KEY', 'c81e728d9d4c2f636f067f89cc14862c', 'PROD');
//
Env.set('CURRENT_ENV', 'LOCAL');

const currentUrl = window.location.href;

if (currentUrl.indexOf('oss.segsat.com') > 0) {
  Env.set('API_URL', '//ws-oss.segsat.com/api');
} else if (
  currentUrl.includes('wdit.in') ||
  currentUrl.includes('weedoit.dev') ||
  currentUrl.includes('lvh.me') ||
  currentUrl.includes('ngrok-free.app')
) {
  Env.set('API_URL', 'https://ssg-api.weedoit.dev/api');
} else {
  Env.set('API_URL', '//' + window.location.hostname + ':3333/api');
}

// @SH_ENV - START - DONT REMOVE THIS LINE!!
Env.set('PROJECT_NAME', 'segsat - agenda');
Env.set('AUTH_DRIVER', 'session');
Env.set('AUTH_ENDPOINT', '/auth/login');
Env.set('AFTER_LOGIN_REDIRECT', '/panel');
Env.set('MY_ACCOUNT_PAGE_PATH', '');
Env.set('LOGOUT_ENDPOINT', '/auth/logout');
Env.set('LOGOUT_ENDPOINT_METHOD', 'GET');
Env.set('PUT_METHOD_SPOOFING', 'querystring');
Env.set('ONSAVE_ERROR', 'falha ao salvar o recurso');
Env.set('ONDELETE_ERROR', 'falha ao tentar deletar');
Env.set('ONUPDATE_SUCCESS', 'atualizado com sucesso');
Env.set('ONLOAD_ERROR', 'falha ao carregar o recurso');
Env.set('VALIDATION_ERROR', 'preencha os campos corretamente.');
Env.set('ONLOGIN_ERROR', 'falha ao realizar o login');
Env.set('NOTIFICATION_SENDING', 'enviando notificação');
Env.set('LOGIN_PAGE_ROUTE', '/login');
Env.set('CHANGE_PASSWORD_REQUIRED', '/update/password');
Env.set('UPDATE_PASSWORD', '/auth/me/password');
Env.set('PASSWORD_UPDATE_ERROR', 'falha ao tentar atualizar a senha');

// @SH_ENV - END - DONT REMOVE THIS LINE!!

App.start();
