class TrackersResource extends RESTFullResource {
    static endpoint () {
        return 'trackers';
    }
    static index (page = 1, q) {
        const params = {
            page,
            ...q
        };
        return this.request('GET', '/', params);
    }
    static show (id) {
        return this.request('GET', id);
    }
    static store (data) {
        return this.request('POST', '/', data);
    }
    static update (id, data) {
        return this.request('PUT', id, data);
    }
    static destroy (id) {
        return this.request('DELETE', id);
    }
    static showTrackersByInstallerId (id) {
        return this.request('GET', `?installer_id=${id}`);
    }
    static defects (id) {
        return this.request('GET', '/defects');
    }
}